import "../css/pages/home.css";

import { BsEmojiSunglassesFill } from "react-icons/bs";
import { ImArrowDown } from "react-icons/im";
import howItWorksBig from "./images/random/how_it_works-big.webp";
import howItWorksExtraSmall from "./images/random/how_it_works-extra-small.webp";
import howItWorksMedium from "./images/random/how_it_works-medium.webp";
import howItWorksSmall from "./images/random/how_it_works-small.webp";
import plotBig from "./images/random/plot-big.webp";
import plotExtraSmall from "./images/random/plot-extra-small.webp";
import plotMedium from "./images/random/plot-medium.webp";
import plotSmall from "./images/random/plot-small.webp";
import profilePictureBot from "./images/gems/GalaxyCrystal.webp";
import universeCrystal from "./images/gems/UniverseCrystal.webp";
import { useState } from "react";
import verifiedBot from "./images/random/bot.webp";

export default function HomePage() {
  // useEffect(() => {
  //     const pics = Object.values(images).map(arr => Object.entries(arr)).flat()

  //     Array.from(document.getElementsByClassName('items')).forEach(div => {
  //         console.log(div.clientHeight, div.clientWidth)

  //         var img = '';
  //         for (let i = 0; i < 10; i++) {
  //             const randomPic = pics[Math.floor(Math.random() * pics.length)];
  //             const height = Math.random() * (div.clientHeight - 10);
  //             const width = Math.random() * (div.clientWidth - 10);

  //             img += `<img src="${randomPic[1]}" alt="${randomPic[0]}" style="top: ${height}px; left: ${width}px; position: absolute;">`
  //         }

  //         div.innerHTML = img
  //     });
  // });

  function numberWithCommas(x) {
    if (`${x}`.length === 3) {
      x = `0${x}`;
    }
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }

  function createCommand() {
    const randomNumber = Math.floor(Math.random() * 900 + 99);
    const commandArray = [
      {
        command: "c!mine",
        number: randomNumber,
        text: (
          <>
            <span style={{ backgroundColor: "#212224" }}>UniverseCrystal</span> is shining bright in the rocks!<br></br>
            <br></br>
            Mine it quick before someone else does. <span style={{ backgroundColor: "#212224" }}>First</span> to type{" "}
            <span style={{ backgroundColor: "#212224" }}>c!mine {randomNumber}</span>
          </>
        ),
      },
      {
        command: "c!steal",
        number: randomNumber,
        text: (
          <>
            A gem bag is left unattended. Is that a <span style={{ backgroundColor: "#212224" }}>UniverseCrystal</span>?
            <br></br>
            <br></br>
            Be the <span style={{ backgroundColor: "#212224" }}>first</span> sly one to type{" "}
            <span style={{ backgroundColor: "#212224" }}>c!steal {randomNumber}</span>
          </>
        ),
      },
    ];
    const randomCommand = commandArray[Math.floor(Math.random() * commandArray.length)];
    setCommand(randomCommand);
  }
  const [command, setCommand] = useState();
  const [time, setTime] = useState();

  return (
    <div id="main">
      <div className="main-header">
        <h1>Make your server more active and fun!</h1>
        <h2>A simple bot that spawns items that can be caught and collected!</h2>
        <div>
          <a href="https://discord.com/oauth2/authorize?client_id=780840339409862687&scope=bot%20applications.commands&permissions=268815424">
            Add Catch it!
          </a>
          <a href="https://discord.gg/UA2HdpT">Support Server</a>
        </div>

        <div
          className="arrow"
          onClick={() => {
            document.getElementById("main-content").scrollIntoView({ behavior: "smooth" });
          }}
        >
          <ImArrowDown />
        </div>

        {/* <div className="items" style={{ top: "0", left: "1%", height: "260px", width: "10%" }}></div>
                <div className="items" style={{ top: "0", right: "1%", height: "260px", width: "10%" }}></div>
                <div className="items" style={{ top: "0", left: "1%", height: "40px", width: "98%" }}></div>
                <div className="items" style={{ top: "220px", left: "1%", height: "40px", width: "98%" }}></div> */}
      </div>

      <div className="wave top-wave">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#28475C"
            fillOpacity="1"
            d="M0,128L34.3,133.3C68.6,139,137,149,206,128C274.3,107,343,53,411,42.7C480,32,549,64,617,90.7C685.7,117,754,139,823,138.7C891.4,139,960,117,1029,138.7C1097.1,160,1166,224,1234,218.7C1302.9,213,1371,139,1406,101.3L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
          ></path>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          style={{ position: "absolute", left: "0", top: "20px" }}
        >
          <path
            fill="#1A2F4B"
            fillOpacity="1"
            d="M0,128L34.3,133.3C68.6,139,137,149,206,128C274.3,107,343,53,411,42.7C480,32,549,64,617,90.7C685.7,117,754,139,823,138.7C891.4,139,960,117,1029,138.7C1097.1,160,1166,224,1234,218.7C1302.9,213,1371,139,1406,101.3L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div className="content" id="main-content">
        <div className="flex">
          <div className="flex-div flex-reverse">
            <div className="text">
              <h3>How it works</h3>
              <p>
                Catch it! is a simple collection theme bot. It will randomly spawn items and currency (in the form of
                luckies 🍀) into the <strong>set spawnchannel</strong> based on chat activity. To catch the goodies,
                read the prompt carefully to be the <ins>first</ins>, <ins>second</ins>, or <ins>third</ins> member to
                type the correct command that is assigned to the item.
                <br></br>
                <br></br>A checkmark (✅) will appear under the correctly typed commands; a cross (❌) will appear under
                the wrongly typed commands. Manage the size of your Plot which collects your items in warehouse storage
                and harvests your 🍀 in the farm.<br></br>
                <br></br>
                The member with the most items will receive the top role!
              </p>
            </div>

            <div className="images">
              <img
                src={howItWorksSmall}
                srcSet={`${howItWorksExtraSmall} 426w, ${howItWorksSmall} 640w, ${howItWorksMedium} 854w, ${howItWorksBig} 1280w`}
                alt="how it works"
              />
            </div>
          </div>

          <div className="flex-div flex-forward">
            <div className="images">
              <img
                src={plotSmall}
                srcSet={`${plotExtraSmall} 426w, ${plotSmall} 640w, ${plotMedium} 854w, ${plotBig} 1280w`}
                alt="plot"
                loading="lazy"
              />
            </div>

            <div className="text">
              <h3>Plots</h3>
              <p>
                Catch it! introduced plots on 7-28-2022 (<strong>v6</strong>) to make the bot more fun! We have heard a
                lot of positive feedback about plots and there are more updates coming soon!
                <br></br>
                <br></br>
                Plots are a piece of land every member has. Plots are server bounded, meaning you have a different plot
                in each server you are in. Plots help you collect luckies (<strong>currency</strong>) and store your
                items.
                <br></br>
                <br></br>
                You can upgrade your plot by using <strong>/plot upgrade</strong> this will give you more land to
                upgrade either your warehouse (the place where items are stored) or your farm (the place where luckies
                are farmed).
                <br></br>
                <br></br>
                Try and get the highest level plot to concur the leaderboard!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="wave">
        <svg style={{ transform: "rotate(180deg)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#28475C"
            fillOpacity="1"
            d="M0,128L34.3,133.3C68.6,139,137,149,206,128C274.3,107,343,53,411,42.7C480,32,549,64,617,90.7C685.7,117,754,139,823,138.7C891.4,139,960,117,1029,138.7C1097.1,160,1166,224,1234,218.7C1302.9,213,1371,139,1406,101.3L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
          ></path>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          style={{
            position: "absolute",
            left: "0",
            top: "-20px",
            transform: "rotate(180deg)",
          }}
        >
          <path
            fill="#1A2F4B"
            fillOpacity="1"
            d="M0,128L34.3,133.3C68.6,139,137,149,206,128C274.3,107,343,53,411,42.7C480,32,549,64,617,90.7C685.7,117,754,139,823,138.7C891.4,139,960,117,1029,138.7C1097.1,160,1166,224,1234,218.7C1302.9,213,1371,139,1406,101.3L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div className="practice">
        <h2>Try it our yourself!</h2>
        <h3>
          Here is a sample of an item spawning in the chat! Click “Start Test!” and type out the command shown to claim
          the item.
        </h3>
        <div className="discord">
          <button
            id="start-test"
            onClick={() => {
              let startBtn = document.getElementById("start-test");
              startBtn.style.opacity = "0";
              setTimeout(() => {
                createCommand();
                startBtn.classList.toggle("hidden");

                let input = document.getElementById("discord-input");
                input.disabled = false;

                let typing = document.getElementById("loading-dots");
                typing.classList.toggle("hidden");

                setTimeout(() => {
                  typing.classList.toggle("hidden");

                  let botMsg = document.getElementById("bot-message");
                  botMsg.classList.toggle("hidden");

                  setTime(Date.now());
                }, 3000);
              }, 1000);
            }}
          >
            Start Test!
          </button>

          <button
            id="reset-test"
            className="hidden"
            onClick={() => {
              let resetBtn = document.getElementById("reset-test");
              resetBtn.classList.toggle("hidden");
              resetBtn.style.opacity = "0";

              let botMsg = document.getElementById("bot-message");
              botMsg.classList.toggle("hidden");

              let userMsg = document.getElementById("user-message");
              userMsg.classList.toggle("hidden");

              let startBtn = document.getElementById("start-test");
              startBtn.classList.toggle("hidden");
              startBtn.style.opacity = "1";

              let caughtTitle = document.getElementById("caught-title");
              caughtTitle.classList.toggle("hidden");
              caughtTitle.style.opacity = "0";
              caughtTitle.innerHTML = "";

              let caughtMsg = document.getElementById("caught-message");
              caughtMsg.classList.toggle("hidden");
              caughtMsg.style.opacity = "0";
              caughtMsg.innerHTML = "";

              let opacityDiv = document.getElementById("opacity-changer");
              opacityDiv.style.opacity = "1";
            }}
          >
            Restart Test!
          </button>

          <p className="hidden" id="caught-title"></p>
          <p className="hidden" id="caught-message"></p>

          <div id="opacity-changer">
            <div className="messages">
              <div className="message hidden" id="bot-message">
                <div className="profile-picture">
                  <img src={profilePictureBot} alt="profile" loading="lazy"></img>
                </div>

                <div>
                  <p className="username">Catch it!</p>
                  <img src={verifiedBot} alt="verified bot" className="verified" loading="lazy"></img>
                  <p className="time">Today at {new Date().toLocaleTimeString().slice(0, -3)}</p>

                  <div style={{ width: "100%" }}>
                    <div className="embed">
                      <div className="text">
                        <p className="title">UniverseCrystal</p>
                        <p className="description">{command ? command.text : ""}</p>
                        <p className="footer">
                          {command ? `${command.command} ${command.number}` : ""} || Type the command first!
                        </p>
                      </div>
                      <div className="thumbnail">
                        <img src={universeCrystal} alt="universe crystal" loading="lazy" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="message hidden" id="user-message">
                <div className="profile-picture">
                  <img
                    src="https://cdn.discordapp.com/avatars/287896981584740353/dabc361cc7091ad66a0d8737e54380ae.webp?size=256"
                    alt="profile"
                    className="profile-picture"
                    loading="lazy"
                  ></img>
                </div>

                <div>
                  <p className="username">Elijah</p>
                  <p className="time">
                    Today at {new Date().getHours()}:{new Date().getMinutes()}
                  </p>
                  <p id="message-content"></p>
                </div>
              </div>
            </div>
            <form>
              <div>
                <svg aria-hidden="false" width="24" height="24" viewBox="0 0 24 24">
                  <g fill="none" fillRule="evenodd">
                    <path
                      className="attachButtonPlus-3IYelE"
                      fill="currentColor"
                      fillRule="nonzero"
                      d="M22.031 12.665c-.923-.4338-1.9384-.665-2.9778-.665-.695 0-1.3663.1013-2 .29V11h-4V7h-2v4h-4v2h4v4h1.29c-.1887.6337-.29 1.305-.29 2 0 1.0394.2312 2.055.665 2.978-.2207.0146-.4424.022-.665.022-2.6522 0-5.1957-1.0536-7.071-2.929C3.1067 17.1958 2.053 14.6523 2.053 12c0-5.5228 4.4772-10 10-10 2.6522 0 5.1957 1.0536 7.071 2.929 1.8754 1.8753 2.929 4.4188 2.929 7.071 0 .2225-.0074.4443-.022.665zM15.4457 13c-.9793.59-1.8023 1.413-2.3924 2.3924V13h2.3924z"
                    ></path>
                    <path
                      className="attachButtonPlay-1ATmb5"
                      fill="currentColor"
                      d="M19.0532 14c1.326 0 2.598.5268 3.5355 1.4645.9377.9376 1.4645 2.2094 1.4645 3.5355 0 1.326-.5268 2.598-1.4645 3.5355C21.651 23.4732 20.3793 24 19.0532 24c-1.326 0-2.5978-.5268-3.5355-1.4645C14.58 21.598 14.0532 20.326 14.0532 19c0-2.7614 2.2386-5 5-5zm-1 7l3-2-3-2v4z"
                    ></path>
                  </g>
                </svg>
              </div>
              <input
                id="discord-input"
                placeholder="Message #test"
                maxLength={20}
                disabled={true}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    let userMsg = document.getElementById("user-message");
                    userMsg.classList.toggle("hidden");

                    let msgContent = document.getElementById("message-content");
                    msgContent.innerHTML = ev.target.value;

                    let opacityDiv = document.getElementById("opacity-changer");
                    opacityDiv.style.opacity = "0.5";

                    let caughtTitle = document.getElementById("caught-title");
                    caughtTitle.classList.toggle("hidden");
                    setTimeout(() => {
                      caughtTitle.style.opacity = "1";
                    }, 300);

                    let caughtMsg = document.getElementById("caught-message");
                    caughtMsg.classList.toggle("hidden");
                    setTimeout(() => {
                      caughtMsg.style.opacity = "1";
                    }, 300);

                    let input = document.getElementById("discord-input");
                    if (input.value.toLowerCase() === `${command.command} ${command.number}`) {
                      caughtTitle.style.color = "#0FF000";
                      caughtTitle.innerHTML = "Correct!";
                      caughtMsg.innerHTML = `You collected the item in ${numberWithCommas(
                        Date.now() - time - 500
                      )} seconds.`;
                    } else {
                      caughtTitle.style.color = "#FF0000";
                      caughtTitle.innerHTML = "Incorrect!";
                    }

                    let resetBtn = document.getElementById("reset-test");
                    resetBtn.classList.toggle("hidden");
                    setTimeout(() => {
                      resetBtn.style.opacity = "1";
                    }, 300);

                    input.disabled = true;
                    input.value = "";
                  }
                }}
              />
              <div className="hide-phone">
                <svg width="24" height="24" aria-hidden="false" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.886 7.999H20C21.104 7.999 22 8.896 22 9.999V11.999H2V9.999C2 8.896 2.897 7.999 4 7.999H7.114C6.663 7.764 6.236 7.477 5.879 7.121C4.709 5.951 4.709 4.048 5.879 2.879C7.012 1.746 8.986 1.746 10.121 2.877C11.758 4.514 11.979 7.595 11.998 7.941C11.9991 7.9525 11.9966 7.96279 11.9941 7.97304C11.992 7.98151 11.99 7.98995 11.99 7.999H12.01C12.01 7.98986 12.0079 7.98134 12.0058 7.97287C12.0034 7.96282 12.0009 7.95286 12.002 7.942C12.022 7.596 12.242 4.515 13.879 2.878C15.014 1.745 16.986 1.746 18.121 2.877C19.29 4.049 19.29 5.952 18.121 7.121C17.764 7.477 17.337 7.764 16.886 7.999ZM7.293 5.707C6.903 5.316 6.903 4.682 7.293 4.292C7.481 4.103 7.732 4 8 4C8.268 4 8.519 4.103 8.707 4.292C9.297 4.882 9.641 5.94 9.825 6.822C8.945 6.639 7.879 6.293 7.293 5.707ZM14.174 6.824C14.359 5.941 14.702 4.883 15.293 4.293C15.481 4.103 15.732 4 16 4C16.268 4 16.519 4.103 16.706 4.291C17.096 4.682 17.097 5.316 16.707 5.707C16.116 6.298 15.057 6.642 14.174 6.824ZM3 13.999V19.999C3 21.102 3.897 21.999 5 21.999H11V13.999H3ZM13 13.999V21.999H19C20.104 21.999 21 21.102 21 19.999V13.999H13Z"
                  ></path>
                </svg>
              </div>
              <div className="hide-phone">
                <svg width="24" height="24" className="icon-1d5zch" aria-hidden="false" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M2 2C0.895431 2 0 2.89543 0 4V20C0 21.1046 0.89543 22 2 22H22C23.1046 22 24 21.1046 24 20V4C24 2.89543 23.1046 2 22 2H2ZM9.76445 11.448V15.48C8.90045 16.044 7.88045 16.356 6.74045 16.356C4.11245 16.356 2.66045 14.628 2.66045 12.072C2.66045 9.504 4.23245 7.764 6.78845 7.764C7.80845 7.764 8.66045 8.004 9.32045 8.376L9.04445 10.164C8.42045 9.768 7.68845 9.456 6.83645 9.456C5.40845 9.456 4.71245 10.512 4.71245 12.06C4.71245 13.62 5.43245 14.712 6.86045 14.712C7.31645 14.712 7.64045 14.616 7.97645 14.448V12.972H6.42845V11.448H9.76445ZM11.5481 7.92H13.6001V16.2H11.5481V7.92ZM20.4724 7.92V9.636H17.5564V11.328H19.8604V13.044H17.5564V16.2H15.5164V7.92H20.4724Z"
                  ></path>
                </svg>
              </div>
              <div className="hide-phone">
                <svg width="24" height="24" className="stickerIcon-3Jx5SE" aria-hidden="false" viewBox="0 0 20 20">
                  <path
                    fill="currentColor"
                    className=""
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0002 0.662583V5.40204C12.0002 6.83974 13.1605 7.99981 14.5986 7.99981H19.3393C19.9245 7.99981 20.222 7.29584 19.8055 6.8794L13.1209 0.196569C12.7043 -0.219868 12.0002 0.0676718 12.0002 0.662583ZM14.5759 10.0282C12.0336 10.0282 9.96986 7.96441 9.96986 5.42209V0.0583083H1.99397C0.897287 0.0583083 0 0.955595 0 2.05228V18.0041C0 19.1007 0.897287 19.998 1.99397 19.998H17.9457C19.0424 19.998 19.9397 19.1007 19.9397 18.0041V10.0282H14.5759ZM11.9998 12.2201C11.9998 13.3245 11.1046 14.2198 10.0002 14.2198C8.8958 14.2198 8.00052 13.3245 8.00052 12.2201H6.66742C6.66742 14.0607 8.15955 15.5529 10.0002 15.5529C11.8408 15.5529 13.3329 14.0607 13.3329 12.2201H11.9998ZM4.44559 13.331C4.44559 13.9446 3.94821 14.442 3.33467 14.442C2.72112 14.442 2.22375 13.9446 2.22375 13.331C2.22375 12.7175 2.72112 12.2201 3.33467 12.2201C3.94821 12.2201 4.44559 12.7175 4.44559 13.331ZM16.6657 14.442C17.2793 14.442 17.7766 13.9446 17.7766 13.331C17.7766 12.7175 17.2793 12.2201 16.6657 12.2201C16.0522 12.2201 15.5548 12.7175 15.5548 13.331C15.5548 13.9446 16.0522 14.442 16.6657 14.442Z"
                  ></path>
                  <path
                    fill="currentColor"
                    className="hidden-334jci"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0002 0.662583V5.40204C12.0002 6.83974 13.1605 7.99981 14.5986 7.99981H19.3393C19.9245 7.99981 20.222 7.29584 19.8055 6.8794L13.1209 0.196569C12.7043 -0.219868 12.0002 0.0676718 12.0002 0.662583ZM14.5759 10.0282C12.0336 10.0282 9.96986 7.96441 9.96986 5.42209V0.0583083H1.99397C0.897287 0.0583083 0 0.955595 0 2.05228V18.0041C0 19.1007 0.897287 19.998 1.99397 19.998H17.9457C19.0424 19.998 19.9397 19.1007 19.9397 18.0041V10.0282H14.5759ZM12 13H11.2H8.8H8C8 14.1046 8.89543 15 10 15C11.1046 15 12 14.1046 12 13ZM17.7766 13.331C17.7766 13.9446 17.2793 14.442 16.6657 14.442C16.0522 14.442 15.5548 13.9446 15.5548 13.331C15.5548 12.7175 16.0522 12.2201 16.6657 12.2201C17.2793 12.2201 17.7766 12.7175 17.7766 13.331ZM2 12.2361L2.53532 11L5.62492 12.7835C5.79161 12.8797 5.79161 13.1203 5.62492 13.2165L2.53532 15L2 13.7639L3.32339 13L2 12.2361Z"
                  ></path>
                </svg>
              </div>
              <div>
                <BsEmojiSunglassesFill />
              </div>
            </form>

            <div id="loading-dots" className="hidden">
              <img
                src="https://c.tenor.com/NqKNFHSmbssAAAAi/discord-loading-dots-discord-loading.gif"
                alt="dots"
                loading="lazy"
              />
              <p>
                <strong>Catch it!</strong> is typing...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
