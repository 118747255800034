import { BiSupport } from "react-icons/bi";
import { ImUserPlus } from "react-icons/im";
import { IoIosHome } from "react-icons/io";

export default function FooterComponent() {
  return (
    <div id="footer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        style={{ position: "absolute", left: "0", bottom: "20px" }}
      >
        <path
          fill="#28475C"
          fillOpacity="1"
          d="M0,128L34.3,133.3C68.6,139,137,149,206,128C274.3,107,343,53,411,42.7C480,32,549,64,617,90.7C685.7,117,754,139,823,138.7C891.4,139,960,117,1029,138.7C1097.1,160,1166,224,1234,218.7C1302.9,213,1371,139,1406,101.3L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        ></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#1A2F4B"
          fillOpacity="1"
          d="M0,128L34.3,133.3C68.6,139,137,149,206,128C274.3,107,343,53,411,42.7C480,32,549,64,617,90.7C685.7,117,754,139,823,138.7C891.4,139,960,117,1029,138.7C1097.1,160,1166,224,1234,218.7C1302.9,213,1371,139,1406,101.3L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
        ></path>
      </svg>

      <div className="footer-text">
        <div className="icons">
          <button onClick={() => (window.location.href = "/")} title="Home page">
            <IoIosHome style={{ fontSize: "24px" }} />
          </button>
          <button onClick={() => (window.location.href = "https://discord.gg/UA2HdpT")} title="Support Server">
            <BiSupport style={{ fontSize: "25px" }} />
          </button>
          <button
            onClick={() =>
              (window.location.href =
                "https://discord.com/oauth2/authorize?client_id=780840339409862687&scope=bot%20applications.commands&permissions=268815424")
            }
            title="Invite Bot"
          >
            <ImUserPlus style={{ fontSize: "22px" }} />
          </button>
        </div>
        <p>© kevin-bot.com</p>
      </div>
    </div>
  );
}
