import "./css/main.css";
import "./css/other/header.css";
import "./css/other/navbar.css";
import "./css/other/footer.css";
import "./css/other/other.css";

import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";

import FooterComponent from "./components/other/footer";
import FourOneFourPage from "./pages/404";
import HomePage from "./pages/home";
import NavbarComponent from "./components/other/navbar";

const DocsPage = lazy(() => import("./pages/docs"));
const LeaderboardPage = lazy(() => import("./pages/leaderboard"));
const LeaderboardGuildPage = lazy(() => import("./pages/leaderboardGuild"));

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={Layout(<HomePage />)} />
        <Route path="/docs" element={<Suspense fallback={<>...</>}>{Layout(<DocsPage />)}</Suspense>} />
        <Route
          path="/leaderboard"
          exact={true}
          element={<Suspense fallback={<>...</>}>{Layout(<LeaderboardPage />)}</Suspense>}
        />
        <Route
          path="/leaderboard/:id"
          element={<Suspense fallback={<>...</>}>{Layout(<LeaderboardGuildPage />)}</Suspense>}
        />
        <Route path="/iframe" element={<HomePage />} />
        <Route path="*" element={Layout(<FourOneFourPage />)} />
      </Routes>
    </>
  );
}

function Layout(component) {
  return (
    <>
      <NavbarComponent />
      {component}
      <FooterComponent />
    </>
  );
}

export default App;
